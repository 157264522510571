import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { StaticImage } from "gatsby-plugin-image";

import GoogleMap from './map'
import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon';
import WhatsappIcon from '../icons/WhatsappIcon';
import configSource from '../config.json';

const Home = ({ location }) => {

  useEffect(() => {
    if (location.state && location.state.contacts) {
      setTimeout(
        function () {
          scrollTo('#contatti');
          if (typeof window !== `undefined`) {
            window.history.replaceState({}, '');
          }
        }
      );
    }
  });

  return (
    <div>
      <Grid
        container
        justify='center'
        alignItems='center'
      >
        <Grid item md={6} sm={12} xs={12}>
          <div className='main-left-square'>
            <div style={{ textAlign: 'center' }}>
              <div className='brand'>
                <h1>T</h1>
                <StaticImage src="../images/brand.png" fullWidth height={150} placeholder="blurred" alt='O' />
                <h1>SI</h1>
              </div>
              <span className='subtitle'>pizzeria</span>
              <Link
                className='menu-button'
                to='/menu'
              >
                Guarda menu
            </Link>
            </div>
          </div>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <div className='main-right-square'>
            <div id='main-right-square-image'>
              <StaticImage 
                src="../images/pizza-main2.jpeg"
                fullWidth
                placeholder="blurred" 
                alt='pizza' 
              />
            </div>
          </div>
        </Grid>

      </Grid>
      <Grid
        container
        justify='center'
        alignItems='center'
        className='second-section'
      >

        <Grid item md={6} sm={12} xs={12}>
          <div className='second-left-square'>
            <StaticImage 
              src="../images/mozzarella2.jpeg"
              fullWidth
              placeholder="blurred" 
              alt='mozzarella' 
            />
          </div>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <div className='second-right-square'>
            <div>
              <div className='title'>
                <h2>
                  La migliore versione
              </h2>
                <h2>
                  della tua pizza preferita
              </h2>
              </div>
              <span className='main-text'>
                Impasto giornaliero con il grano baciato dal sole italiano. Utilizziamo solo i prodotti di qualità DOP, IGP e Chilometro zero per garantire l'eccellenza nel vostro piatto.
            </span>
            </div>
          </div>
        </Grid>

      </Grid>
      <Grid
        container
        justify='flex-start'
        alignItems='center'
        spacing={10}
        className='third-section'
      >

        <Grid item md={6} sm={12} xs={12}>
          <StaticImage 
            src="../images/buffalo2.jpeg"
            fullWidth
            placeholder="blurred" 
            alt='buffalo'
            className='third-section-img'
            align='left'
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <StaticImage 
            src="../images/vegetariana2.jpeg"
            fullWidth
            placeholder="blurred" 
            alt='vegetariana'
            className='third-section-img'
            align='right'
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <StaticImage
            src="../images/margherita2.jpeg"
            fullWidth
            placeholder="blurred"
            alt='margherita'
            className='third-section-img'
            align='left'
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <div className='schedule'>
            <h3>
              Orari di apertura
          </h3>
            <span className='main-text'>
              <span className='day'>martedì - domenica: </span>
              <span className='time'>19:00 - 23:00</span>
            </span>
            <span className='main-text'>
              <span className='day'>lunedi - giorno di chiusura </span>
            </span>
          </div>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <StaticImage
            src="../images/gamberi-pesto2.jpeg"
            fullWidth
            placeholder="blurred"
            alt='gamberi-pesto'
            className='third-section-img'
            align='left'
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <StaticImage
            src="../images/speck-carciofi.jpeg"
            fullWidth
            placeholder="blurred"
            alt='speck-carciofi'
            className='third-section-img'
            align='right'
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <StaticImage
            src="../images/gnocco.JPG"
            fullWidth
            placeholder="blurred"
            alt='gnocco'
            className='third-section-img'
            align='left'
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <StaticImage
            src="../images/pizza-chiusa.jpeg"
            fullWidth
            placeholder="blurred"
            alt='pizza-chiusa'
            className='third-section-img'
            align='right'
          />
        </Grid>

      </Grid>
      <Grid
        container
        justify='center'
        alignItems='center'
      >

        <Grid item id='contatti'>
          <div className='contact-us'>
            <h2>
              Contatti
          </h2>
            <div className='address'>
              <span className='main-text '>
                piazza Aldo Moro 6/8, Graffignana, LO 26813
            </span>
              <a className='main-text' href='tel:0371-88132'>0371-88132</a>
            </div>
            <div className='contact-us-social'>
              <Grid
                container
                justify='center'
                alignItems='center'
                spacing={6}
              >
                <Grid item>
                  <a href={configSource.socialLinks.facebook} target='_blank' rel='noopener noreferrer'>
                    <FacebookIcon />
                  </a>
                </Grid>
                <Grid item>
                  <a href={configSource.socialLinks.instagram} target='_blank' rel='noopener noreferrer'>
                    <InstagramIcon />
                  </a>
                </Grid>
                <Grid item>
                  <a href={configSource.socialLinks.whatsapp} target='_blank' rel='noopener noreferrer'>
                    <WhatsappIcon />
                  </a>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>

      <div>
        <GoogleMap />
      </div>
    </div>
  );
}

export default Home
