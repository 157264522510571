import React from 'react';

import Layout from '../components/layout';
import Home from '../components/home';
import Seo from '../components/seo';

const IndexPage = (props) => {

  return (
    <Layout location={props.location}>
      <Seo title={'Home'} />
      <Home location={props.location} data={props.data} />
    </Layout>
  )
}

export default IndexPage