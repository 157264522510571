import React from 'react';
import GoogleMapReact from 'google-map-react';

const GoogleMap = () => {
  const lat = 45.211210;
  const lng = 9.448632;

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: lat, lng: lng },
      map,
      title: 'TOSI',
      url: 'https://www.google.com/maps?daddr=TOSI+pizzeria,+piazza+Aldo+moro,+6-8,+26813+Graffignana+LO'
    });
    maps.event.addListener(marker, 'click', function () {
      window.open(this.url, '_blank');
    });
    const infowindow = new maps.InfoWindow({
      content: 'TOSI',
    });
    infowindow.open(map, marker);
    return marker;
  };

  return (
    <div className='map'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDQHWlxnXY2WVH08bwU3RTperw5zALMruM' }}
        defaultCenter={{ lat, lng }}
        defaultZoom={16}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
      </GoogleMapReact>
    </div>
  );
}

export default GoogleMap